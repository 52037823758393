<template>
  <div class="GlobalTable">
    <GlobalTable ref="GlobalTable" v-loading="loading" :columns="tableColumns" :data="seachDataList"
      :currentPage="page.pageNum" :maxHeight="600" :total="page.total" @handleCurrentChange="handleCurrentChange"
      :isPagination="false">
      <el-table-column label="公众号名称" slot="wechatSubscribeName" align="center">
        <template slot-scope="{ row }">
          <span style="color: #0981FF;text-decoration: underline;cursor: pointer;" @click="toAdvantage(row)">{{
            row.publicName
            || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="添加人" slot="addpeople" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.adminName }}-{{ row.adminAcc }}</span>
        </template>
      </el-table-column>
      <el-table-column label="单日推送上限" slot="Dailylimit" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.pushLimit/10000 }}万</span>
        </template>
      </el-table-column>
      <el-table-column label="当日已推送量" slot="pushesday" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.used }}</div>
          <el-button size="mini" type="primary" @click="updataalldata">查询</el-button>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" round @click="resertSction(row.publicAppId)">重置记录</el-button>
          <el-button size="mini" type="danger" round @click="actionReset(row)">立即重置</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 添加公众号 -->
    <el-dialog  @closed="handleDialogClosed" title="添加公众号" :visible.sync="IndependentDialogVisible" :close-on-click-modal="false" width="700px">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <div>
          <p style="font-weight: bold;font-size: 15px;">公众号配置：</p>
          <el-form-item label="公众号名称:" label-width="110px" prop="publicName">
            <el-input disabled v-model="ruleForm.publicName" placeholder="请输入公众号名称"></el-input>
          </el-form-item>
          <el-form-item label="公众号AppID:" label-width="110px" prop="publicAppId">
            <el-input disabled v-model="ruleForm.publicAppId" placeholder="请输入公众号AppID"></el-input>
          </el-form-item>
          <el-form-item label="单日推送上限:" label-width="110px" prop="pushLimit">
            <div style="width: 350px;display: inline-block;"> <el-input  oninput="this.value = this.value.replace(/[^\d]/g,'')"
                v-model="ruleForm.pushLimit" placeholder="请输入单日推送上限"></el-input></div>
            <span style="margin-left: 10px;">万</span>
          </el-form-item>
        </div>
        <div>
          <p style="margin-bottom: 10px;font-weight: bold;font-size: 15px;">重置消息配置：</p>
          <el-form-item label="是否开启自动重置公众号消息" label-width="210px" prop="isOpenReset">
            <el-switch v-model="ruleForm.isOpenReset" active-text="开" inactive-text="关">
            </el-switch>
          </el-form-item>
          <div style="margin: -10px 0 20px 17px;" v-if="ruleForm.isOpenReset">
            <span>消息量达到</span>
            <div style="width: 150px;display: inline-block;"> <el-input oninput="this.value = this.value.replace(/[^\d]/g,'')" 
                v-model="ruleForm.resetLimit" placeholder="请输入" maxlength="13"></el-input></div>
            <span>时自动重置</span>
          </div>
        </div>
        <div>
          <p style="margin-bottom: 10px;font-weight: bold;font-size: 15px;">推送配置：</p>
          <el-form-item label="是否开启轮循推送" label-width="145px" prop="smsSign">
            <el-switch v-model="ruleForm.isLoopPush" active-text="开" inactive-text="关">
            </el-switch>
          </el-form-item>
          <div style="margin: -10px 0 20px 17px;" v-if="ruleForm.isLoopPush">
            <span>商家启用消息推送的账号≥</span>
            <div style="width: 150px;display: inline-block;"> <el-input  @input="onPushpush($event)" v-model="ruleForm.openPushStaffNum" placeholder="请输入"
                ></el-input></div>
            <span>个时，触法轮循推送</span>
          </div>
          <el-form-item label="设置轮循推送消息时间段" label-width="190px" prop="smsSign" v-if="ruleForm.isLoopPush">
            <div style="display: flex;">
              <el-time-select placeholder="起始时间" v-model="ruleForm.loopStartTime" :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '24:00'
              }">
              </el-time-select>
              <span style="margin: 0 20px;">~</span>
              <el-time-select placeholder="结束时间" v-model="ruleForm.loopEndTime" :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '24:00',
                minTime: ruleForm.loopStartTime
              }">
              </el-time-select>
            </div>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button size="medium" @click="resetForm('ruleForm')">取消</el-button>
          <el-button size="medium" type="primary" @click="submitForm('ruleForm')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 保存公众号操作 -->
    <el-dialog title="操作提示" :visible.sync="onelineVisible" :close-on-click-modal="false" width="500px"
      @closed="onelineClosed">
      <div class="enable-tip">
        <div>
          说明：提交后将立即生效，请确认好信息
        </div>
        <div class="tip-sure">是否确认保存信息？</div>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onelineVisible = false">取消</el-button>
        <el-button type="primary" :loading="onelineBtnLoading" @click.native="onelineSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 重置操作 -->
    <el-dialog title="重置提示" :visible.sync="EnableDialogVisible" :close-on-click-modal="false" width="500px"
      @closed="EnableClosed">
      <div class="enable-tip">
        <div>
          说明：请勿轻易操作重置，避免浪费推送资源
        </div>
        <div class="tip-sure">重置后本月仅剩<span style="color: red;">{{ publicData.monthResetSubCount }}</span>次机会,是否确认重置？</div>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="EnableDialogVisible = false">取消</el-button>
        <el-button type="primary" :loading="onelineBtnLoading" @click.native="EnableConfirmDialog">确定</el-button>
      </span>
    </el-dialog>
    <!-- 重置记录 -->
    <el-dialog title="重置记录" :visible.sync="StorebackgroundDialogVisible" :close-on-click-modal="false" width="1000px"
      @closed="remarkClosed">
      <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="选择时间">
            <el-date-picker v-model="ymValue" @change="yearMothselect" type="daterange" start-placeholder="开始日期"
              end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']">>
            </el-date-picker>
          </el-form-item>
          <el-form-item label="操作状态">
            <el-select v-model="stateType" clearable placeholder="请选择排序方式">
              <el-option v-for="item in sortOption" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="serchvoluntarilySubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <GlobalTable ref="GlobalTable" v-loading="loading" :columns="reswttableColumns" :data="opymodelList"
        :currentPage="resetpage.pageNum" :total="resetpage.total" @handleCurrentChange="configurationChange">
        <el-table-column label="操作人" slot="addpeople" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.adminName }}-{{ row.adminAcc }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作状态" slot="actionStype" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.optState == '01' ? '重置成功' : '重置失败' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" slot="remark" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.note || "--" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" align="center">
          <template slot-scope="{ row }">
            <el-button size="mini" type="primary" round @click="storeSction(row)">添加备注</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="StorebackgroundDialogVisible = false;">取消</el-button>
        <el-button type="primary" :loading="receiptTypeBtnLoading" @click.native="automatedSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 添加备注弹框 -->
    <el-dialog class="acc_dialog" title="添加备注" :visible.sync="addRemarkShow" :close-on-click-modal="false" width="500px"
      @closed="Remarks_log_cl">
      <div style="margin-bottom: 20px">备注内容</div>
      <el-input maxlength="500" type="textarea" :rows="4" placeholder="请输入备注内容,最多500字" v-model="RemarksTxt">
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="Remarks_log_cl">取消</el-button>
        <el-button type="primary" @click.native="Remarks_log_sure">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import _api from "@/utils/request";
export default {
  name: "Table",
  data() {
    return {
      publicData: {},
      RemarksTxt: "",
      addRemarkShow: false,
      startTime: "",
      endTime: "",
      receiptTypeBtnLoading: false,
      ymValue: "",
      stateType: null,
      sortOption: [{ label: "全部", value: "" },
      { label: "重置成功", value: "01" },
      { label: "重置失败", value: "10" },],
      formInline: {},
      opymodelList: [],
      command: "",
      onelineBtnLoading: false,
      onelineVisible: false,
      isFlag: false,
      isResetdata: false,
      flagsmsAccount: '',//短信账号			
      flagsmsSecretKey: '',//短信密钥			
      flagsmsSign: '',//短信签名		
      ruleForm: {
        "command": "",
        "id": "",
        "isLoopPush": false,
        openPushStaffNum:"",
        "isOpenReset": false,
        "loopEndTime": "",
        "loopStartTime": "",
        "resetLimit": "",
        publicAppId: '',//微信公众号appId			
        pushLimit: '',//微信公众号secret			
        publicName: '',	//微信公众号名称
      },
      rules: {
        publicAppId: [
          { required: true, message: '请输入微信公众号appId', trigger: 'blur' },
        ],
        pushLimit: [
          { required: true, message: '请输入单日推送上限', trigger: 'blur' },
        ],
        publicName: [
          { required: true, message: '请输入微信公众号名称', trigger: 'blur' },
        ],
      },
      loading: false,
      StorebackgroundDialogVisible: false,
      deployId: "",
      resetId: "",
      initData: {
        icp: "",//备案号
        copyright: '',//版权信息			
        detailInfo: '',//详细信息			
        domain: '',//门店后台域名		
        leftBgImg: '',//门店-左背景图			
        rightBgImg: '',//门店-右背景图			
        storeBgName: '',//门店后台名称			
        storePcLoginImage: '',//门店后台图片			
      },
      seachDataList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      resetpage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      EnableDialogVisible: false,
      IndependentDialogVisible: false,
      EnableData: {
        id: "",
        isEnable: "",
      },
      remarkData: {
        id: "",
        remark: "",
      },
      delDialogVisible: false,
      remarkTitle: "",
      delFromData: {
        command: "",
        id: "",
      },
      tableData: {
        companyName: "",
        name: "",
      },
      remarkDialogVisible: false,
      reswttableColumns: [
        { label: "所属月份", prop: "createTimeMonth" },
        { slotName: "addpeople" },
        { label: "重置时间", prop: "createTime" },
        { slotName: "actionStype" },
        { label: "当月剩余重置次数", prop: "monthSurplusResetCount" },
        { slotName: "remark" },
        { slotName: "operation" },],
      tableColumns: [
        { slotName: "wechatSubscribeName" },
        { label: "AppID", prop: "publicAppId" },
        { slotName: "addpeople" },
        { label: "添加时间", prop: "createTime" },
        { slotName: "Dailylimit" },
        { label: "本月重置次数", prop: "monthResetCount" },
        { label: "本月剩余次数", prop: "monthResetSubCount" },
        { slotName: "pushesday" },
        { slotName: "operation" },
      ],
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.handleCurrentChange();
    },
  },
  methods: {
    onPushpush(e){
     console.log(e);
     this.ruleForm.openPushStaffNum=e.replace(/[^\d]/g,'')
     if(this.ruleForm.openPushStaffNum>100){
      this.ruleForm.openPushStaffNum=100
     }
    },
    Remarks_log_cl(row) {
      this.addRemarkShow = false;
      this.RemarksTxt = ''
    },
    // 添加备注--确定
    Remarks_log_sure(row) {
      if (!this.RemarksTxt) {
        return this.$message.error('请输入备注内容!');
      }
      _api
        .resetListaddNote({
          resetId: this.resetId,
          note: this.RemarksTxt,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.addRemarkShow = false;
            this.RemarksTxt = ''
            this.configurationChange()
          } else {
            this.$message({
              type: "erro",
              message: res.msg || "操作失败!",
            });
          }
        });
    },
    yearMothselect(e) {
      console.log(e, this.ruleForm.loopEndTime);
    },
    //查询
    serchvoluntarilySubmit() {
      this.configurationChange()
    },
    // 表格切换页面
    configurationChange(val) {
      if (val) {
        this.resetpage.pageNum = val;
      }
      const baseRequest = {
        "endTime": "",
        "optState": this.stateType,
        "publicAppId": this.deployId,
        "startTime": "",
        pageNum: this.resetpage.pageNum,
        pageSize: this.resetpage.pageSize,
      };
      if (this.ymValue) {
        baseRequest.startTime = moment(this.ymValue[0]).format("x"),
          baseRequest.endTime = moment(this.ymValue[1]).format("x");
      }
      this.loading = true;
      _api.resetListPublicma(baseRequest).then((res) => {
        if (res.code === 1) {
          this.opymodelList = res.data.records;
          this.resetpage.total = res.data.total;
          this.resetpage.pageNum = res.data.current;
          this.loading = false;
        }
      });
    },
    automatedSubmit() {
      this.StorebackgroundDialogVisible = false
    },
    onelineSubmit() {
      if (!this.command) {
        return this.$message.error('请输入谷歌动态码!');
      }
      this.ruleForm.command = this.command
      //添加公众号配置
      _api.savePublicnumberconfiguration(this.ruleForm).then(res => {
        this.IndependentDialogVisible = false
        this.onelineVisible = false
        this.$refs['ruleForm'].resetFields();
        this.$message({
          message: '配置成功！',
          type: 'success'
        });
        this.command = ""
        this.ruleForm = {
          "command": "",
          "id": '',
          "isLoopPush": false,
          openPushStaffNum:"",
          "isOpenReset": false,
          "loopEndTime": "",
          "loopStartTime": "",
          "resetLimit": "",
          publicAppId: '',//微信公众号appId			
          pushLimit: '',//			
          publicName: '',	//微信公众号名称
        }
        this.handleCurrentChange();
      })
    },
    onelineClosed() {
      this.command = ''
    },
    closeForm(formName) {
      this.$refs[formName].resetFields();
      this.StorebackgroundDialogVisible = false
    },
    lefthandleChange(e) {
      this.initData.leftBgImg = e
    },
    righthandleChange(e) {
      this.initData.rightBgImg = e
    },
    logohandleChange(e) {
      this.initData.storePcLoginImage = e
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields();
      this.IndependentDialogVisible = false
    },
    submitForm(formName) {
      if (this.ruleForm.isOpenReset && !this.ruleForm.resetLimit) {
        this.$message.error('请输入重置消息量!');
      }
      if (this.ruleForm.isLoopPush && (!this.ruleForm.loopStartTime || !this.ruleForm.loopEndTime)) {
        this.$message.error('请选择完整的轮循推送消息时间!');
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          this.onelineVisible = true
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleDialogClosed(){
      // this.handleCurrentChange();
      // this.$refs['ruleForm'].resetFields();
      // this.ruleForm = {
      //     "command": "",
      //     "id": '',
      //     "isLoopPush": false,
      //     "isOpenReset": false,
      //     "loopEndTime": "",
      //     "loopStartTime": "",
      //     "resetLimit": "",
      //     publicAppId: '',//微信公众号appId			
      //     pushLimit: '',//			
      //     publicName: '',	//微信公众号名称
      //   }
    },
    //更新当日已推送量
    updataalldata() {
      this.handleCurrentChange();
    },
    resetFrom(formName) {
      this.$refs[formName].resetFields();
      this.IndependentDialogVisible = false
      this.handleCurrentChange();
    },
    actionReset(row) {
      this.publicData = row
      this.EnableDialogVisible = true
    },
    //重置备注
    storeSction(row) {
      this.addRemarkShow = true
      this.resetId = row.resetId
      this.RemarksTxt = row.note
    },
    //重置记录
    resertSction(publicAppId) {
      this.deployId = publicAppId
      this.configurationChange()
      this.StorebackgroundDialogVisible = true
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      this.loading = true;
      _api.PublicnumberconfigList().then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data;
          // this.page.total = res.data.total;
          // this.page.pageNum = res.data.current;
          this.loading = false;
        }
      });
    },
    // 跳转商家列表
    toStoreShop(companyName) {
      let routeData = this.$router.resolve({
        path: "/StoreShop/index",
        query: { companyName },
      });
      window.open(routeData.href, "_blank");
    },
    // 重置关闭
    EnableClosed() {
      this.EnableDialogVisible = false;
    },
    //重置
    EnableConfirmDialog() {
      if (!this.command) {
        return this.$message.error('请输入谷歌验证码!');
      }
      _api.resetPublicma({
        "command": this.command,
        "publicAppId": this.publicData.publicAppId
      }).then((res) => {
        if (res.code === 1) {
          this.command = ""
          this.publicData = {}
          this.$message.success(res.msg);
          this.EnableDialogVisible = false;
          this.handleCurrentChange();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 备注说明
    remarkClosed() {
      this.StorebackgroundDialogVisible = false;
      this.isResetdata = true
    },
    remarkClick(row) {
      this.remarkData.id = row.id;
      this.remarkData.remark = row.remark;
      this.remarkTitle = row.remark ? "修改备注" : "添加备注";
      this.remarkDialogVisible = true;
    },
    remarkConfirmDialog() {
      _api.updateRemark(this.remarkData).then((res) => {
        if (res.code === 1) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        this.remarkDialogVisible = false;
        this.handleCurrentChange();
      });
    },
    // 资源配置
    eddBtn(faceCode) {
      const { href } = this.$router.resolve({
        path: "/IndependentManagement/Resources",
        query: {
          faceCode
        },
      });
      window.open(href, "_blank");
    },
    // 公众号配置
    WeChatPublicConfig(faceCode) {
      const { href } = this.$router.resolve({
        path: "/IndependentManagement/WeChatPublicConfig",
        query: {
          faceCode
        },
      });
      window.open(href, "_blank");
    },
    // 部署信息
    toAdvantage(row) {
      this.ruleForm = JSON.parse(JSON.stringify(row))
      this.ruleForm.pushLimit= this.ruleForm.pushLimit/10000
      console.log(this.ruleForm);
      this.IndependentDialogVisible = true
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .command {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    /deep/ .el-input__inner {
      width: 300px;
    }
  }

  .enable-tip {
    color: #ff8080;
    font-size: 14px;
    margin-bottom: 20px;

    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mr-50 {
    margin-right: 50px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .font-w {
    font-weight: bold;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex-as {
    display: flex;
    align-items: flex-start;
  }

  .redColor {
    color: red;
  }
}
</style>
