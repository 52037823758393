<template>
  <div class="class-list">
    <GlobalInfoBar
      title="公众号消息管理"
      content="管理平台公众号消息推送，查看消息重置记录"
    />
    <!-- <Info/> -->
    <GlobalChunk icon="" title="">
      <!-- <div slot="filter"> -->
        <From />
      <!-- </div> -->
      <Table />
    </GlobalChunk>
  </div>
</template>

<script>
import From from "./Form";
import Table from "./Table";
export default {
  name: "class-list",
  components: {
    Table,
    From
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.class-list {
}
</style>