<template>
  <div class="GlobalFormDemo">
    <div style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 20px;">
      <p>公众号管理</p>
      <el-button size="small" type="primary" @click="toAddMerchant">+ 添加公众号</el-button>
    </div>
    <!-- 添加公众号 -->
    <el-dialog title="添加公众号" :visible.sync="EnableDialogVisible" :close-on-click-modal="false" width="700px">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <div>
          <p style="font-weight: bold;font-size: 15px;">公众号配置：</p>
          <el-form-item label="公众号名称:" label-width="110px" prop="publicName">
            <el-input v-model="ruleForm.publicName" placeholder="请输入公众号名称"></el-input>
          </el-form-item>
          <el-form-item label="公众号AppID:" label-width="110px" prop="publicAppId">
            <el-input v-model="ruleForm.publicAppId" placeholder="请输入公众号AppID"></el-input>
          </el-form-item>
          <el-form-item label="单日推送上限:" label-width="110px" prop="pushLimit">
            <div style="width: 350px;display: inline-block;"> <el-input oninput="this.value = this.value.replace(/[^\d]/g,'')" v-model="ruleForm.pushLimit"
                placeholder="请输入单日推送上限"></el-input></div>
            <span style="margin-left: 10px;">万</span>
          </el-form-item>
        </div>
        <div>
          <p style="margin-bottom: 10px;font-weight: bold;font-size: 15px;">重置消息配置：</p>
          <el-form-item label="是否开启自动重置公众号消息" label-width="210px" prop="isOpenReset">
            <el-switch v-model="ruleForm.isOpenReset" active-text="开" inactive-text="关">
            </el-switch>
          </el-form-item>
          <div style="margin: -10px 0 20px 17px;" v-if="ruleForm.isOpenReset">
            <span>消息量达到</span>
            <div style="width: 150px;display: inline-block;"> <el-input oninput="this.value = this.value.replace(/[^\d]/g,'')" v-model="ruleForm.resetLimit" placeholder="请输入"
                maxlength="13"></el-input></div>
            <span>时自动重置</span>
          </div>
        </div>
        <div>
          <p style="margin-bottom: 10px;font-weight: bold;font-size: 15px;">推送配置：</p>
          <el-form-item label="是否开启轮循推送" label-width="145px" prop="smsSign">
            <el-switch v-model="ruleForm.isLoopPush" active-text="开" inactive-text="关">
            </el-switch>
          </el-form-item>
          <div style="margin: -10px 0 20px 17px;" v-if="ruleForm.isLoopPush">
            <span>商家启用消息推送的账号≥</span>
            <div style="width: 150px;display: inline-block;"> <el-input  @input="onPushpush($event)" v-model="ruleForm.openPushStaffNum" placeholder="请输入"
                ></el-input></div>
            <span>个时，触法轮循推送</span>
          </div>
          <el-form-item label="设置轮循推送消息时间段" label-width="190px" prop="smsSign" v-if="ruleForm.isLoopPush">
            <div style="display: flex;">
              <el-time-select placeholder="起始时间" v-model="ruleForm.loopStartTime" :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '24:00'
              }">
              </el-time-select>
              <span style="margin: 0 20px;">~</span>
              <el-time-select placeholder="结束时间" v-model="ruleForm.loopEndTime" :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '24:00',
                minTime: ruleForm.loopStartTime
              }">
              </el-time-select>
            </div>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button size="medium" @click="resetForm('ruleForm')">取消</el-button>
          <el-button size="medium" type="primary" @click="submitForm('ruleForm')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="操作提示" :visible.sync="onelineVisible" :close-on-click-modal="false" width="500px"
      @closed="onelineClosed">
      <div class="enable-tip">
        <div>
          说明：提交后将立即生效，请确认好信息
        </div>
        <div class="tip-sure">是否确认保存信息？</div>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onelineVisible = false;">取消</el-button>
        <el-button type="primary" :loading="onelineBtnLoading" @click.native="onelineSubmit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      onelineBtnLoading: false,
      onelineVisible: false,
      command: "",
      EnableDialogVisible: false,
      initData: null,
      companyId: "",
      problemDialogVisible: false,
      merchSelectList: [],
      ruleForm: {
        "command": "",
        "id": "",
        "isLoopPush": false,
        openPushStaffNum:"",
        "isOpenReset": false,
        "loopEndTime": "",
        "loopStartTime": "",
        "resetLimit": "",
        publicAppId: '',//微信公众号appId			
        pushLimit: '',//微信公众号secret			
        publicName: '',	//微信公众号名称
      },
      rules: {
        publicAppId: [
          { required: true, message: '请输入微信公众号appId', trigger: 'blur' },
        ],
        pushLimit: [
          { required: true, message: '请输入单日推送上限', trigger: 'blur' },
        ],
        publicName: [
          { required: true, message: '请输入微信公众号名称', trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.handleConfirm();
  },
  methods: {
    onPushpush(e){
     console.log(e);
     this.ruleForm.openPushStaffNum=e.replace(/[^\d]/g,'')
     if(this.ruleForm.openPushStaffNum>100){
      this.ruleForm.openPushStaffNum=100
     }
    },
    onelineSubmit() {
      if (!this.command) {
       return this.$message.error('请输入谷歌动态码!');
      }
      this.ruleForm.command = this.command
      //添加公众号配置
      _api.savePublicnumberconfiguration(this.ruleForm).then(res => {
        this.EnableDialogVisible = false
        this.onelineVisible=false
        this.$refs['ruleForm'].resetFields();
        this.$message({
          message: '配置成功！',
          type: 'success'
        });
        this.command=""
        this.ruleForm={
        "command": "",
        "id": "",
        "isLoopPush": false,
        openPushStaffNum:"",
        "isOpenReset": false,
        "loopEndTime": "",
        "loopStartTime": "",
        "resetLimit": "",
        publicAppId: '',//微信公众号appId			
        pushLimit: '',//			
        publicName: '',	//微信公众号名称
      }
        this.handleConfirm(1);
      })
    },
    onelineClosed() {
      this.command=""
    },
    handleConfirm(data) {
      let SeachParams = {};
      console.log(data);
      if (data) {
        SeachParams = {
          pageNum: 1,
          pageSize: 10,
        };
      }
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    toAddMerchant() {
      this.EnableDialogVisible = true
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.EnableDialogVisible = false
    },
    submitForm(formName) {
      if (this.ruleForm.isOpenReset && !this.ruleForm.resetLimit) {
        this.$message.error('请输入重置消息量!');
      }
      if (this.ruleForm.isLoopPush && (!this.ruleForm.loopStartTime || !this.ruleForm.loopEndTime)) {
        this.$message.error('请选择完整的轮循推送消息时间!');
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          this.onelineVisible = true
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  .command {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    /deep/ .el-input__inner {
      width: 300px;
    }
  }

  .enable-tip {
    color: #ff8080;
    font-size: 14px;
    margin-bottom: 20px;

    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }
}
</style>
